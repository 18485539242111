<template>
  <v-dialog
    v-model="open"
    max-width="960"
  >
    <template #activator="{ on }">
      <v-btn
        v-if="button"
        v-bind="$attrs"
        :color="color"
        fab
        small
        xv-on="on"
        class="mt-n5"
        xelevation="4"
        v-on="on"
      >
        <v-icon small>
          fal fa-plus
        </v-icon>
      </v-btn>

      <template v-else>
        <v-list-item-action
          class="mr-4"
          v-bind="$attrs"
          v-on="on"
        >
          <v-icon small>
            {{ icon }} fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content
          v-bind="$attrs"
          v-on="on"
        >
          <v-list-item-title>
            {{ data && data.id ? "Edit" : "Add" }}
            Newsletter
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
    <v-card>
      <v-card-title>
        <v-icon> {{ icon }} mr-3 </v-icon>
        {{ data && data.id ? "Edit" : "Add" }} Newsletter
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <v-tabs v-model="tab">
              <v-tab>
                <v-icon small>
                  fal fa-edit
                </v-icon>
              </v-tab>
              <v-tab>
                <v-btn
                  text
                  plain
                  :loading="loadingPreview"
                  @click="previewNewsletter"
                >
                  <v-icon> fal fa-eye </v-icon>
                </v-btn>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col
            cols="12"
            class="pb-0"
          >
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-form
                  ref="newsletterForm"
                  v-model="valid"
                >
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="newsletter.date"
                        label="Date"
                        readonly
                        v-bind="attrs"
                        :rules="rules.required"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="newsletter.date"
                      @input="dateMenu = false"
                    />
                  </v-menu>
                  <tiptap-vuetify
                    v-model="newsletter.content"
                    :extensions="extensions"
                  />
                </v-form>
              </v-tab-item>

              <v-tab-item>
                <span
                  v-if="linkPdf === ''"
                  class="d-flex justify-center py-10"
                >Loading pdf preview...</span>
                <pdf
                  v-else
                  :src="linkPdf"
                  :page="1"
                  style="border: 1px solid #666;"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="text-right d-flex">
        <v-btn
          class="ml-auto"
          text
          @click="open = false"
        >
          {{ $t('Cancel') }}
        </v-btn>

        <v-btn
          :loading="loadingCreate"
          color="success"
          @click="updateNewsletter"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
Blockquote,
Bold,
BulletList,
HardBreak,
Heading,
Italic,
Link,
ListItem,
OrderedList,
Paragraph,
Table,
TableCell,
TableHeader,
TableRow,
TiptapVuetify,
Underline,
} from "tiptap-vuetify";

import pdf from "vue-pdf";

export default {
  components: { TiptapVuetify, pdf },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    url: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "fal fa-newspaper",
    },
    color: {
      type: String,
      default: "success",
    },
    button: Boolean,
  },
  data() {
    return {
      tab: null,
      loadedRatio:0,
      extensions: [
        Bold,
        Italic,
        Underline,
        Blockquote,
        Link,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            // Options that fall into the tiptap's extension
            options: {
              levels: [1, 2, 3, 4],
            },
          },
        ],
        Paragraph,
        HardBreak, // line break on Shift + Ctrl + Enter
        Table,
        TableHeader,
        TableCell,
        TableRow,
      ],
      loading: false,
      valid: false,
      dateMenu: false,
      loadingCreate: false,
      loadingPreview: false,
      open: false,
      newsletter: this.data,
      linkPdf: '',
      rules: {
        required: [(v) => !!v || "Required"],
      },
    };
  },
  methods: {
    updateNewsletter() {
      this.$refs.newsletterForm.validate();
      if (this.valid) {
        this.loadingCreate = true;

        this.axios
          .request({
            method: this.newsletter.id ? "patch" : "post",
            url: this.newsletter.id
              ? `admin/newsletters/${this.newsletter.id}`
              : "admin/newsletters",
            data: this.newsletter,
          })
          .then(() => {
            this.$root.$emit("fetch-newsletter");
            this.$toast.success("Newsletter saved");
            if (!this.newsletter.id) {
              this.newsletter = {};
            }
            this.open = false;
          })
          .catch((error) => {
            this.$toast.error(
              error.response.data.content
                ? error.response.data.content[0]
                : error,
            );
          })
          .finally(() => {
            this.loadingCreate = false;
          });
      }
    },
    previewNewsletter() {
      this.loadingPreview = true;
      this.linkPdf = '';

      this.axios
        .request({
          method: "post",
          url: "admin/newsletters/pdf",
          data: this.newsletter,
          responseType: "blob",
        })
        .then((response) => {
          this.linkPdf = window.URL.createObjectURL(new Blob([response.data]));
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {
          this.loadingPreview = false;
        });
    },
  },
};
</script>
